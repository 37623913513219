import React, { ReactNode } from "react";
import { TeliaIcon } from "@telia/b2x-telia-icon";
import { alert, checkCircle, error } from "@teliads/icons";
import { IconName } from "@teliads/icons/dist/main/Icons";

import styles from "./b2x-telia-badge.module.scss";

export const BADGE_VARIANT = {
  ATTENTION: "attention",
  SPECIAL: "special",
  NEUTRAL: "neutral",
  INFORMATION: "information",
  SUCCESS: "success",
  WARNING: "warning",
  ERROR: "error",
  // Deprecated variants below
  SPECIAL_OFFER: "special-offer",
  NOT_AVAILABLE: "not-available",
} as const;

export const badgeVariants = Object.values(BADGE_VARIANT);
export type BadgeVariant = typeof BADGE_VARIANT[keyof typeof BADGE_VARIANT];

const getIcon = (variant: BadgeVariant): string | undefined => {
  switch (variant) {
    case BADGE_VARIANT.SUCCESS:
      return checkCircle.svg;
    case BADGE_VARIANT.WARNING:
      return alert.svg;
    case BADGE_VARIANT.ERROR:
      return error.svg;
    default:
      return;
  }
};

type Props = {
  children: ReactNode;
  variant: BadgeVariant;
  ["data-testid"]?: string;
  icon?: IconName;
  allyLabel?: string;
  className?: string;
};

export const TeliaBadge = ({
  children,
  variant,
  ["data-testid"]: dataTestid,
  icon,
  allyLabel,
  className,
}: Props) => {
  const iconSvg = getIcon(variant);
  const variantClass = variant ? styles[`b2x-telia-badge--${variant}`] : "";

  return (
    <span
      aria-label={allyLabel}
      className={`${className} ${styles["b2x-telia-badge"]} ${variantClass}`}
      data-testid={dataTestid}
    >
      {(iconSvg || icon) && (
        <TeliaIcon
          dataTestid="badge-icon"
          allyTitle={allyLabel}
          name={icon}
          size="sm"
          svg={iconSvg}
        />
      )}
      {children}
    </span>
  );
};
